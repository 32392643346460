<template>
<div>
    <b-card title="" header-tag="header">
        <template v-slot:header>
            <div class="card-title m-0">
                <h3 class="card-label m-0">{{ title }}</h3>
            </div>
            <div class="card-toolbar">

            </div>
        </template>
        <p>Name: {{ contact.name }}</p>
        <p>email: {{ contact.email }}</p>
        <p>Phone: {{ contact.phone }}</p>
        <p>Message: {{ contact.message }}</p>
      
        <form class="form" novalidate="novalidate" id="product_basic">
            
            <b-form-group label-for="input-1">
                <label>Reply :
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                    <b-form-textarea
            v-model="form.admin_reply"
            type="text"
            name="msg"
            class="form-control"
          ></b-form-textarea>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>Status
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-select  v-model="form.status" :options="statusList">
                    
                </b-form-select>
            </b-form-group>

            <b-row>
                <b-col class="text-right">
                    <button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6 ">
                       <i class="las la-save"></i> Save
                    </button>
                </b-col>
            </b-row>
        </form>
    </b-card>
</div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Swal from "sweetalert2";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
//api services
import ContactService from "@/core/services/api/contact";
export default {
    components: {},
    data() {
        return {
            title: "Contact Status Change ",
            statusList: [
                { value: 'created', text: 'Created' },
                { value: 'replied', text: 'Replied' },
                { value: 'closed', text: 'Closed' },
                { value: 'cancelled', text: 'Cancelled' }
            ],
            form: {
                id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
                admin_reply: '',
                status: ''
            },
            contact:{},
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Dashboard",
                route: "/dashboard",
            },
            {
                title: "Contacts",
                route: "/contact",
            },
            {
                title: "Contact : Status Change",
            },
        ]);
        const signin_form = KTUtil.getById("product_basic");
        this.fv = formValidation(signin_form, {
            fields: {
                Vstatus: {
                    validators: {
                        notEmpty: {
                            message: "Verify status is required",
                        },
                    },
                }
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
        this.fv.on("core.form.valid", () => {

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
            var vm = this;
          
           
                this.updateStatus(function(response){
                    vm.response(response,submitButton);
                });
       
               
        });
        this.getContact();
    },
    methods: {
        async getContact() {
            this.contact = await ContactService.getContactById(this.form.id);
            this.form.status = this.contact.status
            this.form.admin_reply = this.contact.admin_reply
        },
        response(response,submitButton){
            var vm = this;
            if (response) {
                let timerInterval;
                Swal.fire({
                    title: response.message,
                    timer: 500,
                    icon: "success",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector("b");
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    vm.$router.push({ name: "contact" })
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {

                    }
                });
            }
            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right",
                "disabled"
            );
        },
        updateStatus(callback) {
            ContactService.updateContact(this.form).then(function (response) {
                callback(response);
            });
        },
    },
    
};
</script>

<style></style>
